import { AutoTranslatorCandidate } from 'src/features/editor/types/AutoTranslatorCandidate';
import { client } from 'src/services/api/client';
import { EndpointConfigSet } from 'src/utils/types/EndpointConfig';
import { LocaleFile, LocaleFileStats } from '../../types/LocaleFile';

const featureKey = 'localefiles';

export default {
  localeFilesStatsForProject: (projectPk: number) => ({
    queryKey: [featureKey, 'localeFilesStatsForProject', projectPk],
    queryFn: () => client.get<LocaleFileStats[]>(`/localefilesstats/?project=${projectPk}`).then(res => res.data),
  }),
  localeFile: (pk: number) => ({
    queryKey: [featureKey, 'localeFile', pk],
    queryFn: () => client.get<LocaleFile>(`/localefiles/${pk}`).then(res => res.data),
  }),
  autoTranslateAllKeys: (localeFilePk: number) => ({
    mutationFn: (languagePk: number) =>
      client.post<AutoTranslatorCandidate[]>(`/localefiles/${localeFilePk}/auto_translate_all_keys/${languagePk}`),
  }),
  clearLanguage: (localeFilePk: number) => ({
    mutationFn: (languagePk: number) => client.post(`/localefiles/${localeFilePk}/clear_language/${languagePk}`),
  }),
} as const satisfies EndpointConfigSet;
