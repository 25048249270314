import { client } from 'src/services/api/client';
import { EndpointConfigSet } from 'src/utils/types/EndpointConfig';

export default {
  putTranslation: (pk: number) => ({
    mutationFn: translation => client.put(`/translations/${pk}/`, translation),
  }),
  postTranslation: {
    mutationFn: translation => client.post(`/translations/`, translation),
  },
  deleteTranslation: (pk: number) => ({
    mutationFn: () => client.delete(`/translations/${pk}/`),
  }),
} as const satisfies EndpointConfigSet;
