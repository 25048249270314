import { client } from 'src/services/api/client';
import { EndpointConfigSet } from 'src/utils/types/EndpointConfig';
import { Export } from '../../types/Export';
import { Import } from '../../types/Import';

const featureKey = 'imports_exports';

export default {
  importsForProject: (projectPk: number) => ({
    queryKey: [featureKey, 'importsForProject', projectPk],
    queryFn: () => client.get<Import[]>(`/imports/?project=${projectPk}`).then(res => res.data),
  }),
  exportsForProject: (projectPk: number) => ({
    queryKey: [featureKey, 'exportsForProject', projectPk],
    queryFn: () => client.get<Export[]>(`/exports/?project=${projectPk}`).then(res => res.data),
  }),
} as const satisfies EndpointConfigSet;
