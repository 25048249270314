import { QueryFunctionContext } from '@tanstack/react-query';
import { AutoTranslatorCandidate } from 'src/features/editor/types/AutoTranslatorCandidate';
import { client } from 'src/services/api/client';
import { Paginated } from 'src/services/api/paginated';
import { EndpointConfigSet } from 'src/utils/types/EndpointConfig';
import { Key, KeyTranslations } from '../../types/Key';

const featureKey = 'keys';

export type KeyTranslationsOrdering = 'name' | '-name' | 'id' | '-latest_translation_edition' | '-creation_timestamp';

export default {
  keyTranslations: (pk: number) => ({
    queryKey: [featureKey, 'keyTranslations', pk],
    queryFn: () => client.get<KeyTranslations>(`/keytranslations/${pk}`).then(res => res.data),
  }),

  keyTranslationsForLocaleFile: (localeFilePk: number, ordering?: KeyTranslationsOrdering) => ({
    queryKey: [featureKey, 'keyTranslationsForLocaleFile', localeFilePk].concat(ordering ? [ordering] : []),
    queryFn: ({ pageParam }: QueryFunctionContext) =>
      client
        .get<Paginated<KeyTranslations[]>>('/keytranslations/', {
          params: { ordering, origin: localeFilePk, page: pageParam as number },
        })
        .then(res => res.data),
    getNextPageParam: (lastPage: Paginated<KeyTranslations[]>) =>
      lastPage.next ? new URL(lastPage.next).searchParams.get('page') : undefined,
    getPreviousPageParam: (_lastPage: Paginated<KeyTranslations[]>, allPages: Paginated<KeyTranslations[]>[]) =>
      allPages[0].previous ? new URL(allPages[0].previous).searchParams.get('page') : undefined,
  }),

  autoTranslateOneKey: (keyPk: number, languagePk: number) => ({
    queryKey: [featureKey, 'autoTranslateOne', keyPk, languagePk],
    queryFn: () =>
      client.post<AutoTranslatorCandidate[]>(`/keys/${keyPk}/auto_translate/${languagePk}`).then(res => res.data),
  }),

  putKey: (pk: number) => ({
    mutationFn: key => client.put<Key>(`/keys/${pk}/`, key),
  }),
} as const satisfies EndpointConfigSet;
