import { RouteConfigSet } from 'src/utils/types/RouteConfig';

export default {
  home: { pathname: '/' },
  login: { pathname: '/login' },
  invitation: (secret: string) => ({ pathname: `/invitation/${secret}` }),
  forgottenPassword: { pathname: '/forgottenPassword' },
  backSsoLogin: { pathname: '/web/login' },
  projects: { pathname: '/projects' },
  projectPk: (pk: number) => ({ pathname: `/projects/${pk}` }),
  projectNew: { pathname: `/projects/new` },
  editor: (projectPk: number, localeFilePk: number) => ({ pathname: `/editor/${projectPk}/${localeFilePk}` }),
  profile: { pathname: '/profile' },
} as const satisfies RouteConfigSet;
