import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { envConfig } from 'envConfig';
import { apiPrefix } from './prefix';

export const config: AxiosRequestConfig = {
  baseURL: `${envConfig.url.api.clientSide}${apiPrefix}`,
  withCredentials: true,

  /* Passing the CSRF token is needed for every non-GET request to the django backend.
   * We tell axios to find the token inside of the "csrftoken" cookie, and then pass it with a header called "X-CSRFToken"
   * for every non-GET request. See https://docs.djangoproject.com/en/4.2/ref/csrf/ for more information. */
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
};

export const noPrefixConfig = { ...config, baseURL: envConfig.url.api.clientSide };

export const client: AxiosInstance = axios.create(config);
export const noPrefixClient: AxiosInstance = axios.create(noPrefixConfig);
