import classNames from 'classnames';
import Link from 'next/link';
import { forwardRef, MouseEventHandler, Ref } from 'react';
import { XOR } from 'src/utils/types/XOR';
import { UrlObject } from 'url';
import { Icon, IconSizeType, IconType } from './Icon';

// Styles

type StyleDef = {
  baseLevelClassName: string;
  topLevelClassName: string;
  baseLevelDisabledClassName: string;
  baseLevelEnabledClassName: string;
  baseLevelIconButtonClassName: string;
  baseLevelLabelButtonClassName: string;
  iconClassName: string;
  labelClassName: string;
};

const baseStyleDef: StyleDef = {
  baseLevelClassName: 'items-center rounded-lg',
  topLevelClassName: 'rounded-lg',
  baseLevelDisabledClassName: 'text-grey-5',
  baseLevelEnabledClassName: '',
  baseLevelIconButtonClassName: 'justify-center p-[10px]',
  baseLevelLabelButtonClassName: 'px-3 py-[10px]',
  iconClassName: '',
  labelClassName: 'text-sm',
};

const styleDefs = {
  primary: {
    topLevelClassName: 'bg-primary',
    baseLevelEnabledClassName: 'text-grey-1 active:bg-primary-dark',
    labelClassName: 'font-semibold',
  },
  secondary: {
    topLevelClassName: 'active:ring-primary-dark ring-primary ring-1 ring-inset ',
    baseLevelEnabledClassName: 'text-white',
    labelClassName: 'font-semibold',
  },
  tertiary: {
    topLevelClassName: 'ring-grey-3 ring-1 ring-inset active:ring-grey-2',
    baseLevelEnabledClassName: 'text-white',
    labelClassName: 'font-semibold',
  },
  tertiaryLight: {
    topLevelClassName: 'ring-grey-4 ring-1 ring-inset active:ring-grey-2',
    baseLevelEnabledClassName: 'text-white',
    labelClassName: 'font-semibold',
  },
  borderless: {
    baseLevelEnabledClassName: 'text-white active:bg-grey-2',
    labelClassName: 'font-semibold',
  },
  squareBorderless: {
    baseLevelClassName: 'rounded-none',
    topLevelClassName: 'rounded-none',
    baseLevelEnabledClassName: 'text-white active:bg-grey-2',
    labelClassName: 'font-semibold',
  },
  list: {
    baseLevelClassName: 'justify-start',
    baseLevelEnabledClassName: 'text-white active:bg-grey-2 hover:bg-grey-3',
    baseLevelLabelButtonClassName: 'px-3 py-[10px]',
    labelClassName: 'font-normal',
  },
  squareList: {
    baseLevelClassName: 'justify-start rounded-none',
    topLevelClassName: 'rounded-none',
    baseLevelEnabledClassName: 'text-white active:bg-grey-2 hover:bg-grey-3',
    baseLevelLabelButtonClassName: 'px-3 py-[10px]',
    labelClassName: 'font-normal',
  },
  squareListDanger: {
    baseLevelClassName: 'justify-start rounded-none',
    topLevelClassName: 'rounded-none',
    baseLevelEnabledClassName: 'text-danger active:bg-grey-2 hover:bg-grey-3',
    baseLevelLabelButtonClassName: 'px-3 py-[10px]',
    labelClassName: 'font-normal',
  },
  danger: {
    topLevelClassName: 'ring-danger ring-1 ring-inset active:ring-danger-dark',
    baseLevelEnabledClassName: 'text-white',
    labelClassName: 'font-semibold',
  },
  topBar: {
    iconClassName: 'text-primary',
    labelClassName: 'text-xs',
  },
} as Record<string, Partial<StyleDef>>;

type ButtonVariant = keyof typeof styleDefs;

const getStyleDef = (variant: ButtonVariant): StyleDef => ({
  baseLevelClassName: classNames(styleDefs[variant].baseLevelClassName, baseStyleDef.baseLevelClassName),
  topLevelClassName: classNames(styleDefs[variant].topLevelClassName, baseStyleDef.topLevelClassName),
  baseLevelDisabledClassName: classNames(
    styleDefs[variant].baseLevelDisabledClassName,
    baseStyleDef.baseLevelDisabledClassName,
  ),
  baseLevelEnabledClassName: classNames(
    styleDefs[variant].baseLevelEnabledClassName,
    baseStyleDef.baseLevelEnabledClassName,
  ),
  baseLevelIconButtonClassName: classNames(
    styleDefs[variant].baseLevelIconButtonClassName,
    baseStyleDef.baseLevelIconButtonClassName,
  ),
  baseLevelLabelButtonClassName: classNames(
    styleDefs[variant].baseLevelLabelButtonClassName,
    baseStyleDef.baseLevelLabelButtonClassName,
  ),
  iconClassName: classNames(styleDefs[variant].iconClassName, baseStyleDef.iconClassName),
  labelClassName: classNames(styleDefs[variant].labelClassName, baseStyleDef.labelClassName),
});

// Props types

type BaseProps = {
  // eslint-disable-next-line react/no-unused-prop-types -- Looks like an ESLint bug
  className?: string;
  disabled?: boolean;
  leftIcon?: IconType;
  rightIcon?: IconType;
  variant?: ButtonVariant;
  // eslint-disable-next-line react/no-unused-prop-types -- Looks like an ESLint bug
  tabIndex?: number;
} & Pick<JSX.IntrinsicElements['button'], 'type' | 'title'>;

type LinkProps = {
  newTab?: boolean;
  scroll?: boolean;
  stopPropagation?: boolean;
  url: string | UrlObject;
  onClickLink?: MouseEventHandler<HTMLAnchorElement>;
};
type ClickProps = { onClick?: MouseEventHandler<HTMLButtonElement> };
type DisplayProps = XOR<{ label: string }, { icon: IconType; iconSize?: IconSizeType }>;

export type ButtonProps = BaseProps & XOR<LinkProps, ClickProps> & DisplayProps;

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  ({ variant = 'primary', disabled = false, type = 'button', tabIndex, ...props }, ref) => {
    const styles = getStyleDef(variant);

    return props.url ? (
      <Link
        href={props.url}
        title={props.title}
        scroll={props.scroll}
        passHref
        target={props.newTab ? '_blank' : undefined}
        ref={ref as Ref<HTMLAnchorElement>}
        onClick={e => {
          if (disabled) {
            e.preventDefault();
          }
          if (props.stopPropagation) {
            e.stopPropagation();
          }
          if (props.onClickLink) {
            props.onClickLink(e);
          }
        }}
        className={classNames('block', { 'cursor-not-allowed': disabled }, styles.topLevelClassName, props.className)}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <StyledButton variant={variant} disabled={disabled} {...props} />
      </Link>
    ) : (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        title={props.title}
        disabled={disabled}
        onClick={props.onClick}
        tabIndex={tabIndex}
        ref={ref as Ref<HTMLButtonElement>}
        className={classNames({ 'cursor-not-allowed': disabled }, styles.topLevelClassName, props.className)}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <StyledButton variant={variant} disabled={disabled} {...props} />
      </button>
    );
  },
);

function StyledButton({
  label,
  icon,
  leftIcon,
  rightIcon,
  iconSize,
  disabled,
  variant = 'primary',
}: ButtonProps): JSX.Element {
  const styles = getStyleDef(variant);

  return (
    <div
      className={classNames(
        'flex h-full w-full flex-row',
        styles.baseLevelClassName,
        disabled ? styles.baseLevelDisabledClassName : styles.baseLevelEnabledClassName,
        label ? styles.baseLevelLabelButtonClassName : styles.baseLevelIconButtonClassName,
      )}
    >
      {leftIcon && (
        <div className={classNames('flex items-center pr-2', styles.iconClassName)}>
          <Icon icon={leftIcon} />
        </div>
      )}
      <div
        className={classNames(
          'flex items-center whitespace-nowrap',
          label ? styles.labelClassName : styles.iconClassName,
        )}
      >
        {label ?? <Icon icon={icon} size={iconSize} />}
      </div>
      {rightIcon && (
        <div className={classNames('flex items-center pl-2', styles.iconClassName)}>
          <Icon icon={rightIcon} />
        </div>
      )}
    </div>
  );
}
