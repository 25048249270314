import { client } from 'src/services/api/client';
import { EndpointConfigSet } from 'src/utils/types/EndpointConfig';
import { Language } from '../../types/Language';

const featureKey = 'languages';

export default {
  language: (pk: number) => ({
    queryKey: [featureKey, 'language', pk],
    queryFn: () => client.get<Language>(`/languages/${pk}`).then(res => res.data),
  }),
  searchLanguage: (partialName: string, exceptIds: number[] = []) => ({
    queryKey: [featureKey, 'searchLanguage', partialName, exceptIds],
    queryFn: () =>
      client
        .get<Language[]>(
          `/languages/?name__icontains=${encodeURIComponent(partialName)}&id__not_in=${exceptIds.join()}`,
        )
        .then(res => res.data),
  }),
} as const satisfies EndpointConfigSet;
