import {
  BookmarkIcon,
  CogIcon,
  MagnifyingGlassIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  MinusCircleIcon,
  ClipboardDocumentIcon,
  ArrowUturnLeftIcon,
  SparklesIcon,
  AdjustmentsVerticalIcon,
  EllipsisVerticalIcon,
  XCircleIcon,
  EnvelopeIcon,
  UserMinusIcon,
  PaperAirplaneIcon,
  UserIcon,
  ArrowRightOnRectangleIcon,
  NoSymbolIcon,
  KeyIcon,
  DocumentMagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import {
  UserCircleIcon,
  BarsArrowDownIcon,
  BookmarkIcon as SolidBookmarkIcon,
  ArrowPathIcon,
  GlobeAsiaAustraliaIcon,
  CheckIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';

export type IconProps = {
  className?: string;
  icon: IconType;
  size?: IconSizeType;
  onClick?: () => void;
};

export const Icon = ({ className, icon, size = 'normal', onClick }: IconProps): JSX.Element => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    className={classNames('flex flex-row items-center', sizes[size], { 'cursor-pointer': onClick != null }, className)}
    onClick={onClick}
    role={onClick ? 'button' : undefined}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={onClick ? 0 : undefined}
  >
    {icons[icon]}
  </div>
);

const icons = {
  userCircle: <UserCircleIcon />,
  user: <UserIcon />,
  removeUser: <UserMinusIcon />,
  sort: <BarsArrowDownIcon />,
  settings: <CogIcon />,
  search: <MagnifyingGlassIcon />,
  reference: <SolidBookmarkIcon className="text-primary" />,
  notReference: <BookmarkIcon />,
  down: <ArrowDownIcon />,
  up: <ArrowUpIcon />,
  remove: <MinusCircleIcon />,
  globe: <GlobeAsiaAustraliaIcon />,
  reload: <ArrowPathIcon />,
  sparkles: <SparklesIcon />,
  copy: <ClipboardDocumentIcon />,
  undo: <ArrowUturnLeftIcon />,
  filter: <AdjustmentsVerticalIcon />,
  check: <CheckIcon />,
  list: <EllipsisVerticalIcon />,
  close: <XCircleIcon />,
  mail: <EnvelopeIcon />,
  send: <PaperAirplaneIcon />,
  logout: <ArrowRightOnRectangleIcon />,
  noResult: <NoSymbolIcon />,
  key: <KeyIcon />,
  details: <DocumentMagnifyingGlassIcon />,
  empty: null,
} as const;

export type IconType = keyof typeof icons;

const sizes = {
  small: 'w-3 h-3',
  normal: 'w-5 h-5',
  bigger: 'w-6 h-6',
  biggerer: 'w-7 h-7',
  double: 'w-10 h-10',
  huge: 'w-32 h-32',
} as const;

export type IconSizeType = keyof typeof sizes;
