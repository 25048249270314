import { client } from 'src/services/api/client';
import { EndpointConfigSet } from 'src/utils/types/EndpointConfig';
import { Project } from '../../types/Project';
import { ProjectApiKeyChange } from '../../types/ProjectApiKeyChange';
import { ProjectGithubIntegrationStatus } from '../../types/ProjectGithubIntegrationStatus';
import { ProjectExportStats, ProjectImportStats } from '../../types/ProjectImportExportStats';

const featureKey = 'projects';

export default {
  myProjects: {
    queryKey: [featureKey, 'myProjects'],
    queryFn: () => client.get<Project[]>('/projects/').then(res => res.data),
  },
  project: (pk: number) => ({
    queryKey: [featureKey, 'project', pk],
    queryFn: () => client.get<Project>(`/projects/${pk}/`).then(res => res.data),
  }),
  testProjectGithubIntegration: (pk: number) => ({
    queryKey: [featureKey, 'testProjectGithubIntegration', pk],
    queryFn: () => client.get<ProjectGithubIntegrationStatus>(`/projects/${pk}/github_test/`).then(res => res.data),
  }),
  putProject: (pk: number) => ({
    mutationFn: project => client.put(`/projects/${pk}/`, project),
  }),
  patchProject: (pk: number) => ({
    mutationFn: partialProject => client.patch(`/projects/${pk}/`, partialProject),
  }),
  postProject: {
    mutationFn: project => client.post(`/projects/`, project),
  },
  deleteProject: (pk: number) => ({
    mutationFn: () => client.delete(`/projects/${pk}/`),
  }),
  importProject: (pk: number) => ({
    mutationFn: () => client.post<ProjectImportStats>(`/projects/${pk}/git_import/`).then(res => res.data),
  }),
  exportProject: (pk: number) => ({
    mutationFn: () => client.post<ProjectExportStats>(`/projects/${pk}/git_export/`).then(res => res.data),
  }),
  nukeProjectLocalRepo: (pk: number) => ({
    mutationFn: () => client.post(`/projects/${pk}/git_repo_delete_local_copy/`),
  }),
  changeProjectApiKey: (pk: number) => ({
    mutationFn: () => client.post<ProjectApiKeyChange>(`/projects/${pk}/change_api_key/`).then(res => res.data),
  }),
} as const satisfies EndpointConfigSet;
