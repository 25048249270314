import importsExportsEndpointConfigs from 'src/features/imports_exports/services/api/endpointConfigs';
import keysEndpointConfigs from 'src/features/keys/services/api/endpointConfigs';
import languagesEndpointConfigs from 'src/features/languages/services/api/endpointConfigs';
import localeFilesEndpointConfigs from 'src/features/localefiles/services/api/endpointConfigs';
import projectsEndpointConfigs from 'src/features/projects/services/api/endpointConfigs';
import translationsEndpointConfigs from 'src/features/translations/services/api/endpointConfigs';
import usersEndpointConfigs from 'src/features/users/services/api/endpointConfigs';
import { EndpointConfigSet } from 'src/utils/types/EndpointConfig';

export default {
  ...usersEndpointConfigs,
  ...projectsEndpointConfigs,
  ...languagesEndpointConfigs,
  ...translationsEndpointConfigs,
  ...localeFilesEndpointConfigs,
  ...languagesEndpointConfigs,
  ...keysEndpointConfigs,
  ...importsExportsEndpointConfigs,
} as const satisfies EndpointConfigSet;
