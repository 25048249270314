import { client, noPrefixClient } from 'src/services/api/client';
import { EndpointConfigSet } from 'src/utils/types/EndpointConfig';
import { Invitation } from '../../types/Invitation';
import { ProfileWrite } from '../../types/ProfileWrite';
import { User } from '../../types/User';

const featureKey = 'users';

export default {
  currentUser: {
    queryKey: [featureKey, 'currentUser'],
    queryFn: () => client.get<User>('/users/me').then(res => res.data),
  },
  user: (pk: number) => ({
    queryKey: [featureKey, 'user', pk],
    queryFn: () => client.get<User>(`/users/${pk}`).then(res => res.data),
  }),
  deleteCurrentUser: {
    mutationFn: () => client.delete(`/users/me`),
  },
  profileWrite: {
    mutationFn: ({ profile, pk }: { profile: ProfileWrite; pk: number }) =>
      client.post(`/users/profile/${pk}`, profile),
  },
  emailLogin: {
    mutationFn: credentials => noPrefixClient.post(`/web/auth_email/`, credentials),
  },
  forgottenPassword: {
    mutationFn: credentials => client.post(`/reset_password/`, credentials),
  },
  logout: {
    mutationFn: () => noPrefixClient.post(`/web/logout/`),
  },
  invitation: (secret: string) => ({
    queryKey: [featureKey, 'invitation', secret],
    queryFn: () => client.get<Invitation>(`/userinvitations/validate/${secret}`).then(res => res.data),
  }),
  invitationsForProject: (projectId: number) => ({
    queryKey: [featureKey, 'invitationsForProject', projectId],
    queryFn: () => client.get<Invitation[]>(`/userinvitations/?project=${projectId}`).then(res => res.data),
  }),
  deleteInvitation: (pk: number) => ({
    mutationFn: () => client.delete(`/userinvitations/${pk}`),
  }),
  invitationResend: (pk: number) => ({
    mutationFn: () => client.post(`/userinvitations/resend/${pk}`),
  }),
  invitationValidate: (secret: string) => ({
    mutationFn: newUser => client.post(`/userinvitations/validate/${secret}`, newUser),
  }),
  invitationCreateIfNeeded: (projectId: number) => ({
    mutationFn: email => client.post(`/userinvitations/create_if_needed/${projectId}`, email),
  }),
} as const satisfies EndpointConfigSet;
