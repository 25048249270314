import NextLink from 'next/link';
import { forwardRef, ReactNode } from 'react';
import { UrlObject } from 'url';

type StyleDef = string;

const styleDefs = {
  primary: 'active:text-primary-dark text-primary underline',
  breadcrumb: 'active:text-primary-dark text-primary',
} as const satisfies Record<string, StyleDef>;

type InternalLinkVariant = keyof typeof styleDefs;

type InternalLinkProps = {
  variant?: InternalLinkVariant;
  children?: ReactNode;
  href: UrlObject | string;
  target?: string;
};

export const InternalLink = forwardRef<HTMLAnchorElement, InternalLinkProps>(
  ({ variant = 'primary', children, href, target }, ref) => {
    const styles = styleDefs[variant];

    return (
      <NextLink href={href} passHref ref={ref} className={styles} target={target}>
        {children}
      </NextLink>
    );
  },
);
